<template>
  <!-- MENSAGEM ENVIADA/RECEBIDA -->
  <div class="chat-segment" v-bind:class="[message.fromMe ? 'chat-segment-sent' : 'chat-segment-get']">
    <div class="chat-message">
      <div v-if="['image', 'video', 'document', 'audio'].includes(message.type_msg)" class="chat-message-text">
        <div v-if="message.type_msg === 'image'">
          <a v-if="message.url_media" :href="message.url_media" download="teste" target="_blank">
            <img :src="message.url_media" class="img" />
          </a>
        </div>
        <div v-else-if="message.type_msg === 'video'">
          <video v-if="message.url_media" controls >
            <source :src="message.url_media" type="video/mp4">
          </video>
        </div>
        <div v-else-if="message.type_msg === 'document'">
          <a :href="message.url_media" target="_blank">
            <div class="document-file p-2 d-flex">
              <div class="file-icon">
              </div>
              <div class="p-1 text-truncate text-truncate-lg">
                <span>{{ message.titleMedia }}</span>
              </div>
            </div>
          </a>
        </div>
        <div v-else-if="message.type_msg === 'audio'">
          <audio v-if="message.url_media" controls>
            <source :src="message.url_media" type="audio/mp3">
          </audio>
        </div>


        <p v-if="message.caption" v-html="message.caption">
        </p>

      </div>
      <!-- <div v-else-if="message.type_msg === 'listMessage'">

      </div>
      <div v-else-if="message.type_msg === 'listResponseMessage'">

      </div> -->
      <div v-else class="chat-message-text">
        <p style="white-space: pre-line;" v-html="message.text_msg">
        </p>
      </div>
    </div>
    <div class="fw-300 text-muted mt-1 fs-xs" :class="{'text-right': message.fromMe }">
      {{ message.created_at_format }}
    </div>
  </div>

  
</template>


<script setup>
import { ref, watch } from 'vue';
import $ from 'jquery';

defineOptions({
  name: 'mensagem-component'
})

const props = defineProps({
  mensagem: {
    type: Object,
    required: true
  }
})

const message = ref(props.mensagem);

watch(() => props.mensagem, () => {
  console.log('MENSAGEM ALTERADA');
  scrollToBottom();
})

function scrollToBottom() {
  console.log('altura inner div chat');
  console.log($('#chat_container').innerHeight());
  $('#msgr_history').scrollTop($('#chat_container').innerHeight())  
}
</script>

<style scoped>
.chat-message {
  padding: .3rem .3rem;
}
.chat-message img, 
.chat-message video {
  width: 260px;
  height: auto;
  border-radius: 0.625rem;
  background: url('@/assets/img/backgrounde_no_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.chat-message audio {
  height: 32px;
  margin-top: 4px;
  background-color: #F1F3F4;
  border-radius: 0.625rem;
}

.chat-message .chat-message-text a {
  text-decoration: none!important;
}
.chat-message .chat-message-text > p {
  padding: 0.2rem .6rem;
  margin: 0;
}
.chat-segment-get .chat-message .chat-message-text .document-file {
  color: rgba(0, 0, 0, 0.8);
  background-color: #dfdfdf;
}
.chat-segment-sent .chat-message .chat-message-text .document-file {
  color: #FFF;
  background-color: #f5f6f661;
}
.chat-message .chat-message-text .document-file {
  border-radius: 0.625rem;
  font-size: 0.95rem;
  font-weight:300;
  align-items: center;
  gap: 4px;
}

.chat-message .chat-message-text .document-file i {

}
.file-icon {
  width: 22.5px;
  height: 26px;
  background: url('@/assets/img/file-bg-icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>