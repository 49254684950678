<template>

  <!-- inbox msgs -->
  <div class="d-flex flex-column flex-grow-1 bg-white">
    <!-- inbox header -->
    <div class="d-flex align-items-center p-0 border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
      <div class="d-flex align-items-center justify-content-between w-100 pl-3 px-lg-4 py-2 position-relative">
        <!-- BEGIN INFO CONTACT -->
        <div class="d-flex flex-row align-items-center mt-1 mb-1">
          <div class="mr-2 d-inline-block">
            <span class="rounded-circle profile-image d-block" :style="contactImage"></span>
          </div>
          <div class="info-card-text">
            <a href="javascript:void(0);" class="fs-lg text-truncate text-truncate-lg" data-toggle="dropdown"
              aria-expanded="false">
              {{ contact.name }}
            </a>
            <!-- <div class="dropdown-menu">
              <a class="dropdown-item px-3 py-2" href="#">Send Email</a>
              <a class="dropdown-item px-3 py-2" href="#">Create Appointment</a>
              <a class="dropdown-item px-3 py-2" href="#">Block User</a>
            </div> -->
            <span class="text-truncate text-truncate-md opacity-80">
              {{ contact.phone }}
            </span>
          </div>
        </div>
        <!-- END INFO CONTACT -->

        <div class="d-flex flex-row align-items-center">
          <!-- BEGIN SEARCH -->
          <div class="input-group input-group-sm bg-white shadow-inset-2">
            <input type="text" class="form-control bg-transparent pr-0"
              placeholder="Buscar na conversa..." v-model="searchMessagesQuery" @input="searchMessages" @keydown.enter="nextMessage">
            <div class="input-group-append">
              <button class="btn btn-outline-default waves-effect waves-themed" type="button" @click="previousMessage"
                title="Mensagem Anterior" data-toggle="tooltip" data-original-title="Mensagem Anterior"
                data-placement="bottom">
                <i class="fal fa-angle-left"></i>
              </button>
              <button class="btn btn-outline-default waves-effect waves-themed" type="button" @click="nextMessage"
                title="Próxima Mensagem" data-toggle="tooltip" data-original-title="Próxima Mensagem"
                data-placement="bottom">
                <i class="fal fa-angle-right"></i>
              </button>

            </div>
          </div>
          <!-- END SEARCH -->


          <!-- BEGIN ACTIONS BUTTON -->
          <div class="btn-group ml-2" role="group">
            <button type="button" class="btn btn-sm btn-outline-default dropdown-toggle waves-effect waves-themed"
              data-toggle="dropdown" aria-expanded="false" >Ações</button>
            <div class="dropdown-menu" style="">
              <a class="dropdown-item" href="javascript:void(0)" data-toggle="tooltip" data-original-title="Editar"
              @click="editContato">
                Editar contato
              </a>
              <!-- <a class="dropdown-item" href="javascript:void(0)">Ação 02</a> -->
            </div>
          </div>
          <!-- END ACTIONS BUTTON -->

        </div>



      </div>
      <!-- button for mobile -->
      <a href="javascript:void(0);"
        class="px-3 py-2 d-flex d-lg-none align-items-center justify-content-center mr-2 btn waves-effect waves-themed"
        data-action="toggle" data-class="slide-on-mobile-left-show" data-target="#js-chat-contact">
        <i class="fal fa-ellipsis-v h1 mb-0 "></i>
      </a>
      <!-- end button for mobile -->
    </div>
    <!-- end inbox header -->

    <!-- inbox message -->
    <div class="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50">
      <div class="position-absolute pos-top pos-bottom w-100 overflow-hidden">
        <div class="d-flex h-100 flex-row">
          <!-- message list (the part that scrolls) -->
          <!-- BEGIN Atendimentos List -->

          <!-- BEGIN msgr -->
          <div class="msgr d-flex h-100 flex-column bg-white w-100">

            <!-- BEGIN custom-scroll -->
            <div class="custom-scroll flex-1 h-100" style="overflow-y:scroll;">
              <div id="chat_container" class="w-100 p-4" v-if="!loading">
                <!-- MENSAGENS -->
                <Mensagem v-for="mensagem in messages" :key="mensagem.id" :mensagem="mensagem"
                  :id="'message-' + mensagem.id" />
                <!-- END MENSAGENS -->
              </div>
              <Loading v-else />
            </div>
            <!-- END custom-scroll  -->

            <!-- BEGIN CHAT INPUT -->
            <div class="panel-content bg-faded rounded-0">
              <form id="msgr_form" @submit.prevent="sendMessage">
                <textarea rows="2" class="form-control bg-transparent rounded-0" placeholder="Digite uma mensagem"
                  id="msgr_input" style="resize: none;" @keypress="listenKeyCodes"></textarea>

                <div class="d-flex align-items-center py-2 px-2 bg-transparent">
                  <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1 waves-effect waves-themed"
                    data-toggle="tooltip" data-original-title="Inserir Documento" data-placement="top"
                    @click="openExplorerToUpload('document')">
                    <i class="fal fa-file-upload color-fusion-300"></i>
                  </a>
                  <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1 waves-effect waves-themed"
                    data-toggle="tooltip" data-original-title="Inserir Foto" data-placement="top"
                    @click="openExplorerToUpload('image')">
                    <i class="fal fa-camera color-fusion-300"></i>
                  </a>
                  <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1 waves-effect waves-themed"
                    data-toggle="tooltip" data-original-title="Inserir Video" data-placement="top"
                    @click="openExplorerToUpload('video')">
                    <i class="fal fa-camera-movie color-fusion-300"></i>
                  </a>

                  <div class="custom-control custom-checkbox custom-control-inline ml-auto hidden-sm-down">
                    <input type="checkbox" class="custom-control-input" id="defaultInline1">
                    <!-- <label class="custom-control-label" for="defaultInline1">Pressione <strong>ENTER</strong> para enviar</label> -->
                  </div>
                  <button type="submit" class="btn btn-info btn-sm ml-auto ml-sm-0 waves-effect waves-themed"
                    @click="sendMessage">
                    Enviar
                  </button>
                </div>


                <!-- DEBUG DE ARQUIVOS NOS INPUTS -->
                <div class="d-none">
                  <div>
                    <p>DEBUG DE ARQUIVOS</p>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="p-1">
                      <p>Arquivo de imagem:</p>
                      <input type="file" name="image" id="image" @change="setMediaFile"
                        accept="image/png, image/jpeg, image/gif">
                    </div>
                    <div class="p-1">
                      <p>Arquivo de video:</p>
                      <input type="file" name="video" id="video" @change="setMediaFile" accept="video/mp4">
                    </div>
                    <div class="p-1">
                      <p>Arquivo de documento:</p>
                      <input type="file" name="document" id="document" @change="setMediaFile"
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    </div>
                  </div>
                </div>
                <!-- END DEBUG -->

              </form>
            </div>
            <!-- END CHAT INPUT -->
          </div>
          <!-- END msgr -->

        </div>
      </div>
    </div>
    <!-- end inbox message -->
  </div>
  <!-- end inbox msgs -->


  <!-- box tickets -->
  <!-- <div class="d-flex flex-column bg-white border-faded border-left-1 border-right-0 border-bottom-0 border-top-0"
    style="width: 22rem;"> -->
  <div
    class="flex-wrap position-relative slide-on-mobile slide-on-mobile-right bg-white border-faded border-left-1 border-right-0 border-bottom-0 border-top-0"
    style="width: 20rem;">

    <div class="d-flex flex-column bg-white position-absolute pos-top pos-bottom w-100">
      <!-- inbox title -->
      <div class="">
        <div class="d-flex align-items-center p-0 border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
          <div class="d-flex align-items-center justify-content-between w-100 pl-3 px-lg-4 py-2 position-relative">
            <div class="d-flex flex-row align-items-center mt-1 mb-1">
              <div class="mr-2 d-flex align-items-center" style="height: 3.125rem;">
                <h4 class="m-0">Atendimentos</h4>
              </div>
            </div>

            <div>
              <button type="button" class="btn btn-sm btn-outline-default waves-effect waves-themed"
                @click="showForm('Chamado')">
                <i class="fal fa-plus"></i>
                Novo Atendimento
              </button>
            </div>

          </div>
          <!-- button for mobile -->
          <a href="javascript:void(0);"
            class="px-3 py-2 d-flex d-lg-none align-items-center justify-content-center mr-2 btn waves-effect waves-themed"
            data-action="toggle" data-class="slide-on-mobile-right-show" data-target="#js-chat-tickets">
            <i class="fal fa-ellipsis-v h1 mb-0 "></i>
          </a>
          <!-- end button for mobile -->
        </div>
      </div>
      <!-- end inbox msgs -->

      <div class="custom-scroll flex-1 h-100">
        <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
          <!-- BEGIN CHAMADOS -->
          <ContactTickets :contact="contact" @edit="editChamado">
          </ContactTickets>
          <!-- END CHAMADOS -->

        </div>
      </div>


    </div>
    
  </div>
  <ModalSidePanel 
    :show="showFormChamado" 
    :title="currentChamado ? 'Editar Atendimento' : 'Novo Atendimento'" 
    @close="hideForm()"
    @save="submitChamado"
  >
    <ChamadoForm
      ref="chamadoForm"
      :phone="contact.phone"
      :modelValue="currentChamado"
      :submitChamado="submitChamado"
    />
  </ModalSidePanel>
  <!-- end box tickets -->

  <ModalCenteredLarge
    :show="showFormContato"
    :title="'Editar contato: ' + contact.phone"
    @close="hideForm()"
    @save="submitContato"
  >
    <ContactForm
      ref="contactForm"
      :modelValue="contact"
      :phone="contact.phone"
      :submitContato="submitContato"
    />
  </ModalCenteredLarge>

</template>


<script>
import Mensagem from './Mensagem.vue';
import ContactTickets from './ContactTickets.vue';
import Loading from '@/components/generals/LoadingDefault.vue';
import { api as api } from "@/axios.js";
import $ from 'jquery';
import ModalSidePanel from '../modals/ModalSidePanel.vue';
import ChamadoForm from '../forms/ChamadoForm.vue';
import ModalCenteredLarge from '../modals/ModalCenteredLarge.vue';
import ContactForm from '../forms/ContactForm.vue';


export default {
  name: "contact-chat-component",
  props: {
    contact: {
      type: Object,
      required: true
    },
  },
  components: {
    Mensagem,
    ContactTickets,
    Loading,
    ModalSidePanel,
    ChamadoForm,
    ModalCenteredLarge,
    ContactForm
  },
  computed: {
    contactImage() {
      return {
        backgroundImage: `url(${this.contact.metaData.profileThumbnail})`,
        backgroundSize: "cover",
      }
    }
  },
  data() {
    return {
      messages: [],
      image: null,
      video: null,
      document: null,
      file: [],
      loading: true,
      searchMessagesQuery: '',
      currentMessageIndex: 0,
      searchMessagesResults: [],
      showFormChamado: false,
      currentChamado: null,
      showFormContato: false,
    }
  },
  mounted() {
    this.getMessages();
  },
  watch: {
    contact: 'getMessages',
  },
  methods: {
    getMessages() {
      this.messages = [];
      this.loading = true
      const phone = this.contact.phone;
      api.get(`/api/mensagem/${phone}`)
        .then(response => {
          this.messages = response.data;
          this.phone = phone;
          setTimeout(() => {
            this.scrollToBottom()
          }, 100)
          this.loading = false;
        })
        .catch(error => {
          if (error.response) {
            // O servidor respondeu com um código de status fora do intervalo 2xx
            this.errorMessage = `Erro: ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            // A requisição foi feita, mas nenhuma resposta foi recebida
            this.errorMessage = 'Erro na conexão com o servidor.';
          } else {
            // Algo aconteceu na configuração da requisição que acionou um erro
            this.errorMessage = `Erro: ${error.message}`;
          }
        });
    },
    sendMessage() {
      const messageEl = $('#msgr_input');
      const message = $('#msgr_input').val().trim();
      const phone = this.contact.phone;

      let formData = new FormData();
      formData.append('phone', phone)

      if ((this.file.length > 0 && ['image', 'video', 'document'].includes(this.file[0].name)) || this.document) {
        this.file.forEach(element => {
          formData.append(element.name, element.data);
        });
        formData.append('caption', message);
      } else {
        formData.append('message', message);
      }

      let inputs = $('input[type="file"]');
      // console.log(inputs)
      if (message && phone) {
        api.postForm('/api/mensagem/send', formData)
          .then(response => {
            console.log(response.data);
            messageEl.val('');
            inputs.val('');
            this.file = [];
          })
          .catch(error => {
            console.log(error);
          })
      } else {
        console.log('Mensagem e/ou Contato são inválidos!');
      }

    },
    setMediaFile(event) {
      const keyType = event.target.id;
      const type = event.target.files[0].type;
      const typesAvaliables = (keyType == 'image' || keyType == 'video') ? ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'] : (keyType == 'document') ? ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'] : [];

      if (!typesAvaliables.includes(type)) {
        return alert('Tipo de imagem inválido!');
      } else {
        let file = event.target.files[0];
        console.log(file);
        let keyType = event.target.id;
        let obj = {};
        obj.name = keyType;
        obj.data = file;
        this.file.push(obj);
      }
    },
    openExplorerToUpload(idType) {
      const el = document.getElementById(idType);
      el.click();
    },
    scrollToBottom() {
      $('.msgr > .custom-scroll').scrollTop($('#chat_container').innerHeight())
    },
    listenKeyCodes(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    // IMPLEMENTAÇÕES DE BUSCA DE MENSAGENS
    searchMessages() {
      this.searchMessagesResults = [];
      this.currentMessageIndex = 0;
      // REMOVENDO AS CLASSES DE MARCATEXTO
      $('.bg-marcatexto').removeClass('bg-marcatexto');

      if (!this.searchMessagesQuery) {
        this.scrollToBottom();
        return;
      }

      // PERCORRENDO POR TODAS AS MENSAGENS E VERIFICANDO SE A PALAVRA ESTÁ CONTIDA NA MENSAGEM. SE TIVER, ADICIONA O INDEX NA LISTA DE RESULTADOS
      this.messages.forEach((message) => {
        if (
          message.text_msg?.toLowerCase().includes(this.searchMessagesQuery.toLowerCase())
          ||
          message.caption?.toLowerCase().includes(this.searchMessagesQuery.toLowerCase())
        ) {
          this.searchMessagesResults.push(`message-${message.id}`);
        }
      });

      // SE POSSUI PELO MENOS 1 RESULTADO DE BUSCA, SCROLLA PARA O INDEX DO PRIMEIRO RESULTADO
      if (this.searchMessagesResults.length > 0) {
        this.scrollToMessage(this.searchMessagesResults[this.currentMessageIndex]);
      }

    },
    // SCROLLA PARA O INDEX DA MENSAGEM
    scrollToMessage(messageId) {
      // REMOVENDO AS CLASSES DE MARCATEXTO
      $('.bg-marcatexto').removeClass('bg-marcatexto');
      const messageElement = document.getElementById(messageId);
      // VERIFICA SE O ELEMENTO FOI ENCONTRADO
      if (messageElement) {
        // ADD CLASSE DE MARCATEXTO NA MENSAGEM EM EVIDENCIA
        $(`#${messageId} > .chat-message`).addClass('bg-marcatexto')
        // SCROLLA PARA O ELEMENTO
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    // VAI PARA A PRÓXIMA MENSAGEM DOS RESULTADOS
    nextMessage() {
      if (this.searchMessagesResults.length > 0) {
        this.currentMessageIndex = (this.currentMessageIndex + 1) % this.searchMessagesResults.length;
        this.scrollToMessage(this.searchMessagesResults[this.currentMessageIndex]);
      }
    },
    // VAI PARA A MENSAGEM ANTERIOR DOS RESULTADOS
    previousMessage() {
      if (this.currentMessageIndex > 0) {
        this.currentMessageIndex = (this.currentMessageIndex - 1 + this.searchMessagesResults.length) % this.searchMessagesResults.length;
        this.scrollToMessage(this.searchMessagesResults[this.currentMessageIndex]);
      }
    },
    // FIM IMPLEMENTAÇÕES DE BUSCA DE MENSAGENS
    // BEGIN ATENDIMENTO
    showForm(resource) {
      console.log(`Novo ${resource}: ${this.contact.phone}`);
      this[`showForm${resource}`] = true;
    },
    hideForm() {
      this.showFormChamado = false;
      this.currentChamado = null;
      this.showFormContato = false;
    },
    submitChamado() {
      const chamadoForm = this.$refs.chamadoForm;
      if (chamadoForm && typeof chamadoForm.submitForm === 'function') {
        chamadoForm.submitForm();
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },
    // ATUALIZA ATENDIMENTO
    editChamado(chamado) {
      this.currentChamado = chamado;
      this.showFormChamado = true;
    },
    // END ATENDIMENTO
    // BEGIN CLIENTE
    editContato() {
      this.showFormContato = true;
    },
    submitContato() {
      const contactForm = this.$refs.contactForm;
      if (contactForm && typeof contactForm.submitForm === 'function') {
        contactForm.submitForm();
      } else {
        console.error("submitForm não está disponível no contactForm");
      }
    }
    // END CLIENTE

  },

}

</script>