<template>
    <div class="page-wrapper">
      <div class="page-inner">
        <AsideNav />
        <div class="page-content-wrapper">
          <HeaderMain />
          <main id="js-page-content" role="main" class="page-content">
            <div class="d-flex flex-grow-1 p-0 border-faded shadow-4">
                <!-- left slider -->
                <div id="js-chat-contact" class="flex-wrap position-relative slide-on-mobile slide-on-mobile-left border-faded border-left-0 border-top-0 border-bottom-0">
                    <!-- BEGIN msgr-list -->
                    <div v-if="!loading" class="d-flex flex-column bg-faded position-absolute pos-top pos-bottom w-100">
                        <div class="px-3 py-4" >
                                <input type="text" class="form-control bg-white" placeholder="Pesquisar Contato" v-model="searchQuery">
                            <div class="mt-2">
                                <!-- <label class="form-label" for="situacaoChamados">
                                    Clientes com chamados
                                </label> -->
                                <select class="select2 form-control" id="situacaoChamados" v-model="searchFilterSituacao">
                                    <option value="">Todas Situações de Chamados</option>
                                    <option value="abertos">Chamados Abertos</option>
                                    <option value="em_andamento">Chamados Em Andamento</option>
                                    <option value="fechados">Chamados Fechados</option>
                                </select>
                                <!-- PARA TESTES POSTERIORES -->
                                <!-- <vSelect class="" id="situacaoChamados" v-model="searchFilterSituacao" :options="options">
                                </vSelect> -->
                            </div>
                        </div>
                        <div class="flex-1 h-100 custom-scrollbar">
                            <div class="w-100">
                                <!-- <div class="nav-title m-0 px-3 text-muted">Conversas</div> -->
                                <ul v-if="filteredContacts.length > 0"  class="list-unstyled m-0">
                                    <ContactList :contacts="filteredContacts" @contactSelected="setContactChat" />
                                </ul>
                                <div v-else class="p-3 text-muted d-flex justify-content-center flex-column align-items-center h-100" style="gap:10px">
                                    <i class="fal fa-inbox fa-2x"></i>
                                    <h4 class="text-center">Nenhum contato encontrado!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Loading v-else />
                
                    <!-- END msgr-list -->
                </div>
                <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show" data-target="#js-chat-contact"></div> <!-- end left slider -->
                <!-- inbox container -->
                <div class="d-flex flex-row flex-grow-1 bg-white">
                    <ContactChat v-if="contact" v-model="contact" :contact="contact" />
                    <div v-else class="p-3 text-muted d-flex justify-content-center flex-column align-items-center h-100 w-100" style="gap:20px">
                        <i class="fal fa-inbox fa-4x"></i>
                        <h3>
                            Selecione um contato para começar o atendimento
                        </h3>
                    </div>

                </div>
                <!-- end inbox container -->
                 
            </div>

          </main>
          <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>
        </div>
      </div>
    </div>
</template>


<script>
import ContactList from './ContactList.vue';
import ContactChat from './ContactChat.vue';
import {api as api} from "@/axios.js";
import HeaderMain from '../HeaderMain.vue';
import AsideNav from '../AsideNav.vue';
import Loading from '@/components/generals/LoadingDefault.vue';
// import vSelect from 'vue-select';
    
// ASYNC IMPORT
//   const TickectForm = async () => import('./TicketForm.vue');

export default {
    name: 'chat-component',
    components: {
        ContactList,
        ContactChat,
        HeaderMain,
        AsideNav,
        Loading,
        // vSelect
    },
    computed: {
        filteredContacts() {
            const searchQueryLower = this.searchQuery.toLowerCase();
            if (!searchQueryLower && !this.searchFilterSituacao) return this.contacts;
            return this.contacts.filter(contact => {
                return (
                    // contact.chamados[this.searchFilterSituacao]
                    (
                        this.searchFilterSituacao ? contact.chamados[this.searchFilterSituacao] > 0 : true
                    ) &&
                    (
                        contact.name?.toLowerCase().includes(searchQueryLower) 
                        ||
                        contact.phone?.toLowerCase().includes(searchQueryLower) 
                        ||
                        contact.metaData?.name?.toLowerCase().includes(searchQueryLower) 
                        ||
                        contact.metaData?.phone?.toLowerCase().includes(searchQueryLower)
                    )
                );
            });
        }
    },

    data() {
        return {
            messages: null,
            contacts: [],
            contact: null,
            breadcrumb: [{
                text: 'Atendimento',
                href: '#',
                active: true
            }],
            searchQuery: '',
            searchFilterSituacao: '',
            loading: true,
            options: ['abertos', 'em_andamento', 'fechados'],
        }
    },
    created() {
        this.getContacts();
    },

    methods: {
        getContacts() {
            api.get('/api/mensagem/chats').then(response => {
                console.log(response.data);
                this.contacts = response.data;
                this.loading = false;
            }).catch(error => {
            if (error.response) {
                // O servidor respondeu com um código de status fora do intervalo 2xx
                this.errorMessage = `Erro: ${error.response.status} - ${error.response.data.message}`;
            } else if (error.request) {
                // A requisição foi feita, mas nenhuma resposta foi recebida
                this.errorMessage = 'Erro na conexão com o servidor.';
            } else {
                // Algo aconteceu na configuração da requisição que acionou um erro
                this.errorMessage = `Erro: ${error.message}`;
            }
            });
        },
        setContactChat(contact) {
            console.log(contact);
            this.contact = contact;
        },
    }
}
</script>