<script setup>
import { onMounted, ref } from 'vue';
import AsideNav from './AsideNav.vue';
import HeaderMain from './HeaderMain.vue';
import { reactive } from 'vue';
import Loading from './generals/LoadingDefault.vue';
import { api as api } from '@/axios.js';
import AtendimentoCardList from './generals/AtendimentoCardList.vue';


const atendimentos = reactive([]);

const setores = [
  {
    value: 2,
    name: 'Aceites',
  },
  {
    value: 3,
    name: 'Comunicação de Nascimento',
  },
  {
    value: 4,
    name: 'Pendência de Registro',
  },
  {
    value: 5,
    name: 'Pedido de Abertura de Chamado',
  },
  {
    value: 6,
    name: 'Inscrever em exposições',
  },
  {
    value: 7,
    name: 'Transferências',
  },
  {
    value: 8,
    name: 'Convenção de Condomínios',
  },
  {
    value: 9,
    name: 'Cobrança',
  },
  {
    value: 10,
    name: 'Contratos de Compra e Venda',
  },
]

const clientesParaTeste = [
  {
    id: 1,
    name: 'Willian L',
    phone: '553173093646',
    email: 'S7wEJ1@example.com',
    haras: 'Haras/Fazenda 1',
    notes: 'Observações do cliente 1',
    chamados: {
      abertos: 10,
      andamento: 5,
      fechados: 3
    }
  },
  {
    id: 2,
    name: 'Adriana',
    phone: '11 89999-9999',
    email: 'S7wEJ2@example.com',
    haras: 'Haras/Fazenda 2',
    notes: 'Observações do cliente 2',
    chamados: {
      abertos: 2,
      andamento: 3,
      fechados: 0
    }
  },
  {
    id: 3,
    name: 'Leall',
    phone: '11 79999-9999',
    email: 'S7wEJ3@example.com',
    haras: 'Haras/Fazenda 3',
    notes: 'Observações do cliente 3',
    chamados: {
      abertos: 4,
      andamento: 1,
      fechados: 2
    },
  }
]


const loading = ref(true);


// FILTERS
const searchFilterSetor = ref('');
const searchFilterSituacao = ref('');
const searchFilterUrgencia = ref('');
const searchFilterCliente = reactive('');

const clientes = reactive([]);
const getClientes = () => {
  // if (searchFilterCliente.values.lenght <= 3) return;
  clientes.values = clientesParaTeste;
  console.log(clientes)
}



// BUSCA OS CHAMADOS DESSE CONTATO
const getAtendimentos = () => {
  loading.value = true
  api.get(`/api/chamados`).then(response => {
    atendimentos.values = response.data
    atendimentos.values.filter(atendimento => atendimento.date = new Date(atendimento.created_at))
    loading.value = false
  }).catch(error => {
    if (error.response) {
      // O servidor respondeu com um código de status fora do intervalo 2xx
      console.log(`Erro: ${error.response.status} - ${error.response.data.message}`)
    }
  })
}


const editAtendimento = (atendimento) => {
  console.log(atendimento)
}




onMounted(() => {
  console.log('Mounted Page Clients');
  getClientes()
})

</script>

<template>

  <div class="page-wrapper">
    <div class="page-inner">
      <AsideNav />
      <div class="page-content-wrapper">
        <HeaderMain />
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">

            <div
              class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
              id="js-slide-left">
              <div class="row fullsize-table overflow-auto">
                <div class="col-12 bg-body stickTop">
                  <div class="col-12 p-0">
                    <button class="btn btn-info w-100" id="clientes-new"><i class="fal fa-1x fa-plus mr-1"></i> Novo
                      Atendimento
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <form @submit.prevent="getAtendimentos" id="filterForm" class="row">

                    <div class="col-12 mt-2">
                      <!-- <label for="filter-search" class="form-label">Pesquisar por Palavra-Chave</label> -->
                      <input type="search" placeholder="Pesquisar por Palavra-Chave" autocomplete="off"
                        class="form-control " data-table-api="search" id="filter-search" v-model="searchQuery">
                    </div>
                    <div class="col-12 mt-2">
                      <select class="select2 form-control" id="setorAtendimentos" v-model="searchFilterSetor">
                        <option value="">Todos os Setores</option>
                        <option v-for="setor in setores" :key="setor.value" :value="setor.value">{{ setor.name }}
                        </option>
                      </select>
                    </div>

                    <div class="col-12 mt-2">
                      <select class="select2 form-control" id="situacaoAtendimentos" v-model="searchFilterUrgencia">
                        <option value="">Todas Urgências</option>
                        <option value="1">Urgência Baixa</option>
                        <option value="2">Urgência Média</option>
                        <option value="3">Urgência Alta</option>
                      </select>
                    </div>
                    <div class="col-12 mt-2">
                      <select class="select2 form-control" id="clientesAtendimentos" v-model="searchFilterCliente">
                        <option value="">Selecione o cliente</option>
                        <option v-for="client in clientes.values" :value="client.id" :key="client.id">{{ client.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 mt-2">
                      <select class="select2 form-control" id="situacaoAtendimentos" v-model="searchFilterSituacao">
                        <option value="">Todas Situações de Atendimentos</option>
                        <option value="Aberto">Atendimentos Abertos</option>
                        <option value="Andamento">Atendimentos Em Andamento</option>
                        <option value="Fechado">Atendimentos Fechados</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8"><button class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        type="submit" form="CTable-Filter-Form" id="btn-filter" @click="getAtendimentos"><i class="fal fa-search mr-1"></i>
                        Pesquisar</button></div>
                    <div class="col-4"><button class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        data-toggle="tooltip" title="" data-placement="top" type="button" id="btn-clear-filter"
                        data-original-title="Limpar Filtro" @click="clearFilter"><i class="fal fa-times"></i></button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show"
                data-target="#js-slide-left"></div>


            </div>

            <div class="d-flex flex-column flex-grow-1 overflow-hidden bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-comment"></i> <span class="fw-300">Atendimentos</span>
                      </h2>
                      <div class="panel-toolbar">
                        <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip"
                          data-offset="0,10" data-original-title="Collapse"></button>
                        <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip"
                          data-offset="0,10" data-original-title="Fullscreen"></button>
                        <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10"
                          data-original-title="Close"></button>
                      </div>
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content">

                        <div v-if="atendimentos.values.length <= 0">
                          <div class="w-100 p-1" style="overflow: auto; width: auto; height: 100%;">
                            <div class="px-3 py-2">
                              Block antes de Pesquisar
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="!loading" class="row">
                            <div class="col-4">
                              <div class="w-100 p-1" style="overflow: auto; width: auto; height: 100%;" >
                                <div class="px-3 py-2">
                                  <h5 class="m-0">Abertos</h5>
                                  <hr class="m-0">
                                </div>
                                <div v-if="atendimentos.values.length > 0">
                                  <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter px-3 py-2">
                                    <AtendimentoCardList 
                                      v-for="atendimento in atendimentos.values" :atendimento="atendimento"
                                      :key="atendimento.id"
                                      @atendimentoSelected="editAtendimento" 
                                    />
                                  </ul>
                                </div>
                                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                                <div v-else
                                  class="p-3 text-muted d-flex justify-content-center flex-column align-items-center"
                                  style="gap:10px">
                                  <i class="fal fa-hashtag fa-2x"></i>
                                  <h4 class="text-center">Nenhum atendimento encontrado!</h4>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="w-100 p-1" style="overflow: auto; width: auto; height: 100%;" >
                                <div class="px-3 py-2">
                                  <h5 class="m-0">Em Andamento</h5>
                                  <hr class="m-0">
                                </div>
                                <div v-if="atendimentos.values.length > 0">
                                  <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter px-3 py-2">
                                    <AtendimentoCardList 
                                      v-for="atendimento in atendimentos.values" :atendimento="atendimento"
                                      :key="atendimento.id"
                                      @atendimentoSelected="editAtendimento" 
                                    />
                                  </ul>
                                </div>
                                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                                <div v-else
                                  class="p-3 text-muted d-flex justify-content-center flex-column align-items-center"
                                  style="gap:10px">
                                  <i class="fal fa-hashtag fa-2x"></i>
                                  <h4 class="text-center">Nenhum atendimento encontrado!</h4>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="w-100 p-1" style="overflow: auto; width: auto; height: 100%;" >
                                <div class="px-3 py-2">
                                  <h5 class="m-0">Fechados</h5>
                                  <hr class="m-0">
                                </div>
                                <div v-if="atendimentos.values.length > 0">
                                  <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter px-3 py-2">
                                    <AtendimentoCardList 
                                      v-for="atendimento in atendimentos.values" :atendimento="atendimento"
                                      :key="atendimento.id"
                                      @atendimentoSelected="editAtendimento" 
                                    />
                                  </ul>
                                </div>
                                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                                <div v-else
                                  class="p-3 text-muted d-flex justify-content-center flex-column align-items-center"
                                  style="gap:10px">
                                  <i class="fal fa-hashtag fa-2x"></i>
                                  <h4 class="text-center">Nenhum atendimento encontrado!</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Loading v-else />
                          

                        </div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </main>
      </div>
    </div>
  </div>
</template>