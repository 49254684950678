<template>
<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item"><a href="javascript:void(0);">Atendimento Adriana Leal</a></li>
  <li v-for="item in items" :key="item.text" class="breadcrumb-item" :class="item.active ? 'active' : ''">{{ item.text }}</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date">-</span></li>
</ol>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  items: {
    type: Array,
  },
});

const items = ref(props.items);

</script>