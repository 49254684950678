<script setup>
import { computed } from 'vue';
import { reactive } from 'vue';


const props = defineProps({
  atendimento: {
    type: Object,
    required: true
  }
})

const dateNow = computed(() => {
  return new Date();
})



const atendimento = reactive(props.atendimento);

const statusObj = computed(() => ({
  'badge-success': atendimento.status === 'Fechado',
  'badge-warning': atendimento.status === 'Aberto',
  'badge-danger': atendimento.status === 'Aberto' && atendimento.date < dateNow.value,
}))

console.log(atendimento)
</script>


<style scoped>
.text-title-atendimento {
  display: flex;
  justify-content: space-between;
}
.text-title-atendimento > h5 {
  font-size: 1.2rem;
  font-weight: 700;
}
hr {
  margin-top: .3rem;
  margin-bottom: .3rem;
  border-bottom: 1.5px solid #eee;
}
</style>

<template>
  <li class="bg-white rounded border-faded mb-2">
    <a href="#" class="d-table w-100 px-2 py-2 text-dark hover-white" data-filter-tags="{{ atendimento.assunto }}"
      @click="editChamado(atendimento)">
      <div class="d-table-cell w-100 align-middle pl-2 pr-2">
        <div class="d-flex flex-row justify-content-between">
          <div class="text-title-atendimento">
            <div class='fs-xl fw-500'>
              #{{ atendimento.id }} - {{ atendimento.assunto }}
            </div>
          </div>
          <div>
            <small class="badge ml-auto" :class="statusObj">
              {{ atendimento.created_at_format }}
            </small>
          </div>

        </div>
        <hr>
        <div>
          Atualizado em: {{ atendimento.updated_at_format }}
        </div>

      </div>
    </a>
  </li>
</template>