import axios from 'axios';
import Cookies from 'js-cookie';
import router from './router';
import store from './store';

const api = axios.create({
  baseURL: 'https://api.atendimento.agrobold.com.br',
});

// const api = axios.create({
//   baseURL: 'http://localhost:8989',
// });

// INTERCEPTOR NA REQUISÃO, QUE VERIFICA SE HÁ TOKEN E ADICIONA NO CABECALHO O MAIS ATUAL
api.interceptors.request.use( config => {
  const token = localStorage.getItem('access_token')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config
}, error => {
  return Promise.reject(error)
})

// INTERCEPTOR NA RESPOSTA, VERIFICA SE O TOKEN EXPIROU, A PARTIR DA RESPOSTA DO SERVIDOR E REDIRECIONA PARA TELA DE LOGIN
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log('Usuário não autenticado, redirecionando para página de login!');
      
      // remover tokens e cookies
      localStorage.removeItem('access_token')
      Cookies.remove('XSRF-TOKEN')
      
      // // Atualiza o state no store para deslogado
      store.commit('LOGOUT')
      
      // // Redirect para a rota "home" dentro do sistema
      router.push({ name: 'login' })
    }

    return Promise.reject(error)
  }
)


export {api};