<script setup>
import { reactive } from 'vue';

const optionsSetores = reactive([
  {
    value: 2,
    name: 'Aceites',
  },
  {
    value: 3,
    name: 'Comunicação de Nascimento',
  },
  {
    value: 4,
    name: 'Pendência de Registro',
  },
  {
    value: 5,
    name: 'Pedido de Abertura de Chamado',
  },
  {
    value: 6,
    name: 'Inscrever em exposições',
  },
  {
    value: 7,
    name: 'Transferências',
  },
  {
    value: 8,
    name: 'Convenção de Condomínios',
  },
  {
    value: 9,
    name: 'Cobrança',
  },
  {
    value: 10,
    name: 'Contratos de Compra e Venda',
  },
])

const optionsSituacoes = reactive([
  {
    value: 'Aberto',
    name: 'Aberto',
  },
  {
    value: 'Andamento',
    name: 'Em Andamento',
  },
  {
    value: 'Fechado',
    name: 'Fechado',
  },
])

const optionsUrgencias = reactive([
  {
    value: 1,
    name: 'Baixa',
  },
  {
    value: 2,
    name: 'Média',
  },
  {
    value: 3,
    name: 'Alta',
  },
])


const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  phone: {
    type: String,
    required: true,
  }
})


const emit = defineEmits(['saved']);

const form = reactive({
  id: props.modelValue?.id,
  phone: props.phone,
  assunto: props.modelValue?.assunto,
  descricao: props.modelValue?.descricao,
  status: props.modelValue?.status,
  urgencia: props.modelValue?.urgencia,
  setores: props.modelValue?.setores || [],
  data_final_prevista: props.modelValue?.data_final_prevista,
  observacao: props.modelValue?.observacao,
})


const submitForm = () => {
  console.log("create chamado")
  const data = {
    phone: props.phone,
    id: form.id,
    assunto: form.assunto,
    descricao: form.descricao,
    status: form.status,
    urgencia: form.urgencia,
    setores: form.setores,
    data_final_prevista: form.data_final_prevista,
    observacao: form.observacao
  }
  console.log(data)
  console.log("Form do chamado submetido com sucesso!")
  emit('saved')

}
// EXPONDO A FUNÇÃO PARA FORA DO COMPONENTE, PARA QUE POSSA SER CHAMADA DE OUTRO COMPONENTE
defineExpose({ submitForm })

</script>


<template>
  <form @submit.prevent="submitForm" id="chamadoForm">
    <div class="form-group">
      <label class="form-label" for="assuntoChamado">Assunto</label>
      <input 
        type="text" 
        name="assuntoChamado"
        class="form-control"
        v-model="form.assunto"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="descricao">Descrição</label>
      <input 
        type="text" 
        name="descricao" 
        class="form-control"
        v-model="form.descricao"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="status">Situação</label>
      <select 
        name="status" 
        class="select2 form-control"
        v-model="form.status"
      >
        <option 
          v-for="status in optionsSituacoes" 
          :value="status.value" 
          :key="status.value"
        >
          {{ status.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-label" for="simpleinput">Urgência</label>
      <select 
        name="urgenciaChamados"
        class="select2 form-control"
        v-model="form.urgencia"
      >
        <option 
          v-for="urgencia in optionsUrgencias" 
          :value="urgencia.value" 
          :key="urgencia.value"
        >
          {{ urgencia.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-label" for="dataFinal">Data Final</label>
      <input 
        type="date"
        class="form-control" 
        name="dataFinal" 
        v-model="form.data_final_prevista"
      />
    </div>

    <div class="form-group">
      <label class="form-label" for="setoresChamados">Setores</label>
      <select 
        name="setoresChamados"
        multiple 
        class="select2 form-control"
        v-model="form.setores"
      >
        <option 
          v-for="setor in optionsSetores" 
          :value="setor.value" 
          :key="setor.value"
        >
          {{ setor.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-label" for="observacaoChamado">Text area</label>
      <textarea 
        name="observacaoChamado" 
        class="form-control" 
        id="observacaoChamado" 
        v-model="form.observacao"
        rows="3"
      />
    </div>
  </form>
</template>