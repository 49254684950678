<template>
  <li v-for="contact in contacts" :key="contact.phone" @click="selectContato(contact)">
    <div class="d-flex w-100 px-3 py-2 text-dark hover-white cursor-pointer show-child-on-hover align-items-center">
      <div class="profile-image-lg rounded-circle" :style="imageProfileContact(contact.metaData.profileThumbnail)"></div>
      <div class="px-2 flex-1">
        <div class="text-truncate text-truncate-md">
          {{ contact.name }}
          <small class="d-block text-muted text-truncate text-truncate-md">
            {{ contact.phone }}
          </small>
        </div>
      </div>
      <div class="d-flex align-items-end">
        <span v-if="contact.chamados.abertos > 0" class="badge border-light rounded-pill bg-danger-500 ml-1">{{ contact.chamados.abertos }}</span>
        <span v-if="contact.chamados.em_andamento > 0" class="badge rounded-pill bg-warning-700 text-white ml-1">{{ contact.chamados.em_andamento }}</span>
        <span v-if="contact.chamados.fechados > 0" class="badge rounded-pill bg-success-500 ml-1">{{ contact.chamados.fechados }}</span>
      </div>
    </div>
</li>
</template>


<script>
export default {
  name: 'chat-contatos-component',
  computed: {
  },
  props: {
    contacts: {
    type: Array,
    // required: true
  }

  },

  methods: {
    selectContato(phone) {
      this.$emit('contactSelected', phone);
    },
    imageProfileContact(imgUrl) {
      console.log(imgUrl)
      return {
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
      };
    },
    editContato(phone) {
      // Função para editar contato
      console.log('Editar contato: ' + phone);
    },
  },
  mounted() {
    console.log(this.contacts)
  }
};

</script>