import { createApp } from 'vue'
import '@/assets/css/main.css';
import '@/assets/js/main.js';


import App from './App.vue'
import store from './store'; // Vuex Store
import router from './router.js'; // Vue Router
import select2Plugin from './assets/plugins/select2-plugin';

console.log('Initializing app...');
const app = createApp(App)

app.use(store)
app.use(router)
app.use(select2Plugin)

app.mount('#app')
console.log('App initialized.');