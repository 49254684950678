<script setup>
import Loading from '@/components/generals/LoadingDefault.vue';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { api as api } from '@/axios.js';

const props = defineProps({
  contact: Object, 
})

const emit = defineEmits(['edit']);
function editAtendimento(chamado) {
  console.log(chamado)
  emit('edit', chamado)
}

const setores = [
  {
    value: 2,
    name: 'Aceites',
  },
  {
    value: 3,
    name: 'Comunicação de Nascimento',
  },
  {
    value: 4,
    name: 'Pendência de Registro',
  },
  {
    value: 5,
    name: 'Pedido de Abertura de Chamado',
  },
  {
    value: 6,
    name: 'Inscrever em exposições',
  },
  {
    value: 7,
    name: 'Transferências',
  },
  {
    value: 8,
    name: 'Convenção de Condomínios',
  },
  {
    value: 9,
    name: 'Cobrança',
  },
  {
    value: 10,
    name: 'Contratos de Compra e Venda',
  },
]

const dateNow = computed(() => {
  return new Date();
})

const loading = ref(true);

// FILTERS
const searchFilterSetor = ref('');
const searchFilterSituacao = ref('');


// ATRIBUTOS
const atendimentos = reactive([]);

const statusObj = computed(() => ({
  'badge-success': atendimentos.values.filter(atendimento => atendimento.status === 'Fechado'),
  'badge-warning': atendimentos.values.filter(atendimento => atendimento.status === 'Aberto'),
  'badge-danger': atendimentos.values.filter(atendimento => atendimento.status === 'Aberto' && atendimento.date < dateNow.value),
}))

// BUSCA OS CHAMADOS DESSE CONTATO
const getAtendimentos = () => {
  loading.value = true
  const phone = props.contact.phone;
  if (!phone) return
  api.get(`/api/chamados/phone/${phone}`).then(response => {
    atendimentos.values = response.data
    atendimentos.values.filter(atendimento => atendimento.date = new Date(atendimento.created_at))
    loading.value = false
  }).catch(error => {
    if (error.response) {
      // O servidor respondeu com um código de status fora do intervalo 2xx
      console.log(`Erro: ${error.response.status} - ${error.response.data.message}`)
    }
  })
}


const computedAtendimentos = computed(() => {
  console.log(searchFilterSituacao.value, searchFilterSetor.value)
  return atendimentos.values.filter(atendimento => {
    return (
      (
        searchFilterSetor.value ? atendimento.setores.includes(searchFilterSetor.value) : true
      )
        &&
      (
        searchFilterSituacao.value ? atendimento.status == searchFilterSituacao.value : true
      )
    )
  })         
});


onMounted(() => {
  getAtendimentos()
})

watch(() => props.contact, () => {
  atendimentos.values = []
  getAtendimentos()
})



</script>

<style scoped>
.text-title-atendimento {
  display: flex;
  justify-content: space-between;
}
.text-title-atendimento > h5 {
  font-size: 1.2rem;
  font-weight: 700;
}
hr {
  margin-top: .3rem;
  margin-bottom: .3rem;
  border-bottom: 1.5px solid #eee;
}
</style>

<template>
<div id="chat_container" class="w-100 p-1" style="overflow: auto; width: auto; height: 100%;" v-if="!loading">
  <div class="px-3 py-2" >
    <div class="mt-2">
      <!-- <label class="form-label" for="situacaoAtendimentos">
          Atendimentos por situação
      </label> -->
      <select class="select2 form-control" id="situacaoAtendimentos" v-model="searchFilterSituacao">
          <option value="">Todas Situações de Atendimentos</option>
          <option value="Aberto">Atendimentos Abertos</option>
          <option value="Andamento">Atendimentos Em Andamento</option>
          <option value="Fechado">Atendimentos Fechados</option>
      </select>
    </div>
    <div class="mt-2">
      <!-- <label class="form-label" for="setorAtendimentos">
          Atendimentos por setor
      </label> -->
      <select class="select2 form-control" id="setorAtendimentos" v-model="searchFilterSetor">
          <option value="">Todos os Setores</option>
          <option v-for="setor in setores" :key="setor.value" :value="setor.value">{{ setor.name }}</option>
      </select>
    </div>
  </div>
  <div class="px-3 py-2">
    <hr class="m-0">
  </div>
  <div v-if="computedAtendimentos.length > 0">
    <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter px-3 py-2" >
      <li v-for="atendimento in computedAtendimentos" :key="atendimento.id" class="bg-white rounded border-faded mb-2">
        <a href="#" class="d-table w-100 px-2 py-2 text-dark hover-white"
          data-filter-tags="{{ atendimento.assunto }}" @click="editAtendimento(atendimento)">
          <div class="d-table-cell w-100 align-middle pl-2 pr-2">
            <div class="d-flex flex-row justify-content-between">
              <div class="text-title-atendimento">
                <div class='fs-xl fw-500'>
                  #{{ atendimento.id }} - {{ atendimento.assunto }}
                </div>
              </div>
              <div>
                <small class="badge ml-auto" :class="statusObj">
                  {{ atendimento.created_at_format }}
                </small>
              </div>
            
            </div>
              <hr>
              <div>
                Atualizado em: {{ atendimento.updated_at_format }}
              </div>
              
          </div>
        </a>
      </li>
    </ul>
  </div>
  <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
  <div v-else class="p-3 text-muted d-flex justify-content-center flex-column align-items-center" style="gap:10px">
    <i class="fal fa-hashtag fa-2x"></i>
    <h4 class="text-center">Nenhum atendimento encontrado!</h4>
  </div>
</div>
<Loading v-else />
</template>