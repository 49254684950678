<script setup>
import { reactive } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  phone: {
    type: String,
    required: true,
  }
})

const emit = defineEmits(['saved']);

const form = reactive({
  id: props.modelValue?.id,
  phone: props.phone,
  name: props.modelValue?.nome,
  document_id: props.modelValue?.document_id,
  haras: props.modelValue?.haras,
  image: props.modelValue?.image,
  email: props.modelValue?.email,
  observacoes: props.modelValue?.observacoes,
})


const submitForm = () => {
  const data = {
    id: form.id,
    phone: form.phone,
    name: form.name,
    document_id: form.document_id,
    haras: form.haras,
    image: form.image,
    email: form.email,
    observacoes: form.observacoes
  }
  console.log(data)
  console.log("Form do cliente submetido com sucesso!")
  emit('saved')
}
// EXPONDO A FUNÇÃO PARA FORA DO COMPONENTE, PARA QUE POSSA SER CHAMADA DE OUTRO COMPONENTE
defineExpose({ submitForm })

</script>


<template>
  <form @submit.prevent="submitForm" id="clienteForm">
    <div class="form-group">
      <label class="form-label" for="nomeCliente">Nome</label>
      <input 
        type="text" 
        name="nomeCliente"
        class="form-control"
        v-model="form.name"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="document_id">CPF/CNPJ</label>
      <input 
        type="text" 
        name="document_id" 
        class="form-control"
        v-model="form.document_id"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="haras">Haras/Fazenda</label>
      <input 
        type="text" 
        name="haras" 
        class="form-control"
        v-model="form.haras"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="phone">Celular</label>
      <input 
        type="phone" 
        name="phone" 
        class="form-control"
        v-model="form.phone"
        disabled
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="email">E-mail</label>
      <input 
        type="email" 
        name="email" 
        class="form-control"
        v-model="form.email"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="observacoes">Observações</label>
      <textarea 
        name="observacoes" 
        class="form-control" 
        id="observacoes" 
        v-model="form.observacoes"
        rows="3"
      />
    </div>
  </form>
</template>