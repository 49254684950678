import { createStore } from 'vuex';
import router from './router';

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('access_token')
  },
  mutations: {
    LOGIN() {
      this.state.isLoggedIn = true
    },
    LOGOUT() {
      this.state.isLoggedIn = false
    }
  },
  actions: {
    login({ commit }) {
      commit('LOGIN')
    },
    logout({ commit }) {
      console.log('passou aquii no logout')
      commit('LOGOUT')
      this.dispatch('navigateToLogin')
    },
    navigateToLogin() {
      router.push({ name: 'Login' })
    }
  }
})