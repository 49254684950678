import { createRouter, createWebHistory } from "vue-router"
import Login from "./components/Login"
import Chat from "./components/chat/Chat.vue"
import ClientesPage from "./components/ClientsPage"
import SetoresPage from "./components/SetoresPage.vue"
import ColaboradoresPage from "./components/ColaboradoresPage.vue"
import AtendimentoPorCliente from "./components/relatorios/AtendimentoPorCliente.vue"
import AtendimentoPorColaborador from "./components/relatorios/AtendimentoPorColaborador.vue"
import AtendimentoPorSetor from "./components/relatorios/AtendimentoPorSetor.vue"
import EstatisticasGerais from "./components/relatorios/EstatisticasGerais.vue"
import ConfiguracoesPage from "./components/ConfiguracoesPage.vue"
import HomePage from "./components/HomePage.vue"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    title: "Login Page",
    meta: {
      public: true // rotas publicas
    }
  },
  {
    path: "/",
    name: "home",
    title: "Home - Atendimento",
    component: HomePage,
    meta: {
      public: false
    }
  },
  {
    path: "/atendimento",
    name: "atendimento",
    title: "Atendimento Adriana Leal",
    component: Chat,
    meta: {
      public: false
    }
  },
  {
    path: "/clientes",
    name: "clientes",
    title: "Clientes  - Atendimento",
    component: ClientesPage,
    meta: {
      public: false
    }
  },
  {
    path: "/setores",
    name: "setores",
    title: "Setores  - Atendimento",
    component: SetoresPage,
    meta: {
      public: false
    }
  },
  {
    path: "/colaboradores",
    name: "colaboradores",
    title: "Colaboradores  - Atendimento",
    component: ColaboradoresPage,
    meta: {
      public: false
    }
  },
  {
    path: "/configuracoes",
    name: "configuracoes",
    title: "Configurações - Atendimento",
    component: ConfiguracoesPage,
    meta: {
      public: false
    }
  },
  // RELATÓRIOS
  {
    path: "/relatorios/atendimento-por-cliente",
    name: "atendimento-por-cliente",
    title: "Relatórios - Atendimento por Cliente",
    component: AtendimentoPorCliente,
    meta: {
      public: false
    }
  },
  {
    path: "/relatorios/atendimento-por-colaborador",
    name: "atendimento-por-colaborador",
    title: "Relatórios - Atendimento por Colaborador",
    component: AtendimentoPorColaborador,
    meta: {
      public: false
    }
  },
  {
    path: "/relatorios/atendimento-por-setor",
    name: "atendimento-por-setor",
    title: "Relatórios - Atendimento por Setor",
    component: AtendimentoPorSetor,
    meta: {
      public: false
    }
  },
  {
    path: "/relatorios/estatisticas-gerais",
    name: "estatisticas-gerais",
    title: "Relatórios - Estatísticas Gerais",
    component: EstatisticasGerais,
    meta: {
      public: false
    }
  },
  // END RELATÓRIOS

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("access_token");
  if(!to.meta.public && !isAuthenticated) {
    next({ name: "login" })
  }else {
    next()
  }
})

export default router;