<template>

  <div class="page-wrapper">
    <div class="page-inner">
      <AsideNav />
      <div class="page-content-wrapper">
        <HeaderMain />
        <main id="js-page-content" role="main" class="page-content">
          <BreadcrumbMain :items="breadcrumb" />

          <div class="d-flex flex-grow-1 p-0">

            <div
              class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
              id="js-slide-left">
              <div class="row fullsize-table overflow-auto">
                <div class="col-12 bg-body stickTop">
                  <div class="col-12 p-0">
                    <button class="btn btn-info w-100" id="colaboradores-new"><i class="fal fa-1x fa-users-crown mr-1"></i> Novo
                      Colaborador
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <form @submit.prevent="submit" id="filterForm" class="row">

                    <div class="col-12 mt-2">
                      <label for="filter-search" class="form-label">Pesquisar por Palavra-Chave</label>
                      <input type="search" autocomplete="off" class="form-control " data-table-api="search"
                        id="filter-search" v-model="searchQuery">
                    </div>
                    <div class="col-12 mt-2">
                      <label class="form-label" for="situacaoChamados">
                        Setor
                      </label>
                      <select class="select2 form-control w-100" id="situacaoChamados" v-model="setor">
                        <option value="">Todos</option>
                        <option v-for="setor in setores" :value="setor.id" :key="setor.id">{{ setor.name }}</option>
                      </select>
                    </div>
                    <div class="col-12 mt-2">
                      <label class="form-label" for="situacaoChamados">
                        Situação de Chamados
                      </label>
                      <select class="select2 form-control w-100" id="situacaoChamados" v-model="situacao">
                        <option value="">Todos</option>
                        <option value="aberto">Aberto</option>
                        <option value="andamento">Em Andamento</option>
                        <option value="fechados">Fechados</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8"><button class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        type="submit" form="CTable-Filter-Form" id="btn-filter"><i
                          class="fal fa-search mr-1 mt-1"></i>
                        Pesquisar</button></div>
                    <div class="col-4"><button class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        data-toggle="tooltip" title="" data-placement="top" type="button" id="btn-clear-filter"
                        data-original-title="Limpar Filtro" @click="clearFilter"><i class="fal fa-times mt-1"></i></button></div>
                  </div>
                </div>
              </div>

              <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show"
                data-target="#js-slide-left"></div>


            </div>

            <div class="d-flex flex-column flex-grow-1 overflow-hidden bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-users-crown"></i> <span class="fw-300">Colaboradores</span>
                      </h2>
                      <div class="panel-toolbar">
                        <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip"
                          data-offset="0,10" data-original-title="Collapse"></button>
                        <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip"
                          data-offset="0,10" data-original-title="Fullscreen"></button>
                        <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10"
                          data-original-title="Close"></button>
                      </div>
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content">

                        <!-- datatable start -->
                        <!-- <DataTable :columns="columns" :data="computedSetores" :options="options"
                          class="table table-bordered table-hover table-striped w-100 mb-0">
                        </DataTable> -->
                        <!-- datatable end -->

                        <DataTable class="table table-bordered table-hover table-striped w-100 mb-0" :options="options">
                          <thead>
                            <tr>
                              <th class="cursor-pointer">*</th>
                              <th class="cursor-pointer">Colaborador</th>
                              <th class="cursor-pointer">Setores</th>
                              <th class="cursor-pointer">Celular</th>
                              <th class="cursor-pointer">E-mail</th>
                              <th class="text-center cursor-pointer">Chamados Abertos</th>
                              <th class="text-center cursor-pointer">Chamados em Andamento</th>
                              <th class="text-center cursor-pointer">Chamados Fechados</th>
                              <th class="text-center cursor-pointer">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(colaborador, index) in colaboradores" :key="colaborador.id">
                              <td>{{ ++index }}</td>
                              <td>{{ colaborador.name }}</td>
                              <td>
                                <div v-for="setor in colaborador.setores" :key="setor.id">
                                  {{ setor.name }}
                                </div>
                              </td>
                              <!-- <td>{{ colaborador.document_id }}</td> -->
                              <td>{{ colaborador.phone }}</td>
                              <td>{{ colaborador.email }}</td>
                              <td class="text-center">{{ colaborador.chamados.abertos }}</td>
                              <td class="text-center">{{ colaborador.chamados.andamento }}</td>
                              <td class="text-center">{{ colaborador.chamados.fechados }}</td>
                              <td class='text-center' width="100px">
                                <button @click='console.log(`Editar: ${colaborador.name}`)' class='btn btn-sm btn-light btn-icon rounded-circle waves-effect waves-themed mr-2' data-toggle='tooltip' data-original-title='Editar Colaborador'>
                                  <i class='fal fa-pencil'></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </DataTable>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </main>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AsideNav from './AsideNav.vue';
import HeaderMain from './HeaderMain.vue';
import BreadcrumbMain from './generals/BreadcrumbMain.vue';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import 'datatables.net-scroller-bs4';

DataTable.use(DataTablesCore);

const breadcrumb = [
  {
    text: 'Colaboradores',
    href: '#',
    active: true
  }
];

const props = defineProps({
  colaboradores: {
    type: Array,
    default: () => [
      {
        id: 1,
        name: 'Colaborador 1',
        setores: [
          {
            id: 1,
            name: 'Setor 1',
            colaboradores: 5,
          },
          {
            id: 5,
            name: 'Setor 5',
            colaboradores: 2,
          }
        ],
        phone: '5531977778888',
        email: 'S7wEJ111@example.com',
        chamados: {
          abertos: 3,
          andamento: 5,
          fechados: 4
        }
      },
      {
        id: 2,
        name: 'Colaborador 2',
        setores: [
          {
            id: 2,
            name: 'Setor 2',
            colaboradores: 3,
          },
          {
            id: 5,
            name: 'Setor 5',
            colaboradores: 2,
          }
        ],
        phone: '5531988887777',
        email: 'S7wEJ222@example.com',
        chamados: {
          abertos: 10,
          andamento: 1,
          fechados: 3
        }
      },
    ]
  },
  setores: {
    type: Array,
    default: () => [
      {
        id: 1,
        name: 'Setor 1',
      },
      {
        id: 2,
        name: 'Setor 2',
      }
    ]
  }
});

const options = {
  // deferRender: true,
  // scrollY: 120,
  // height: 120,
  // scroller: true,
  dom: 't',
  language: {
    "decimal": ",",
    "emptyTable": "No data available in table",
    "info": "Mostrando _START_ a _END_ de _TOTAL_ resultados",
    "infoEmpty": "Nenhum Resultado Encontrado",
    "thousands": ".",
    "loadingRecords": "Carregando...",
    "processing": "",
    "search": "",
    "searchPlaceholder": "Pesquisar",
    "zeroRecords": "No matching records found",
    "paginate": {
      "first": "Primeiro",
      "last": "Último",
      "next": "Próximo",
      "previous": "Anterior"
    },
    "aria": {
      "orderable": "Ordenar por essa coluna",
      "orderableReverse": "Ordem inversa desta coluna"
    }
  }
}

// const columns = [
//   { data: 'count', title: '*' },
//   { data: 'name', title: 'Nome' },
//   { data: 'phone', title: 'Telefone' },
//   { data: 'email', title: 'E-mail' },
//   { data: 'actions', title: 'Ações' },
// ];


const colaboradores = ref(props.colaboradores);
const setores = ref(props.setores);


onMounted(() => {
  console.log('Mounted Page Colaboradores');
  console.log(colaboradores);
})

// V-MODELS
const searchQuery = ref('');
const situacao = ref('');
const setor = ref('');
const clearFilter = () => {
  searchQuery.value = '';
  situacao.value = '';
  setor.value = '';
}
</script>